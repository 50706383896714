import React from 'react';
import CardItemEnlarge from './CardItemEnlarge';
import './Cards.css';
import './CardsEnlarge.css'
import "@fontsource/league-spartan";
import { HashLink } from 'react-router-hash-link';

function ThankYouMsg() {
  return (
    <div className='cards__e'>
        <div className='thank__you__msg'>
            <h4 id="thankyou"> Thank you, we will reach out to you soon.</h4>
            <h6><br/>Meanwhile, feel free to check out our other works below: </h6>
        </div>
        <div className="cards__container">
            <div className="cards__wrapper">
                <ul className='cards__items__e'>
                    <CardItemEnlarge src='images/bukit_timah_kitchen.jpg' />
                    <CardItemEnlarge src='images/serangoon_master_bed.jpg' />
                </ul>
                <ul className='cards__items__e'>
                    <CardItemEnlarge src='images/woodlands_ring_master_bed.jpg' />
                    <CardItemEnlarge src='images/jervois_road_master_toilet.jpg' />
                </ul>
            </div>
        </div>
        <HashLink smooth to='/#portfolio' style={{color: "#fff"}}>
            {/* <HashLink smooth to='/#portfolio' className='nav-links'> 🔙 */}
            <p style={{fontSize: "36px", margin: "-18px 0px 0px", padding: "0px 0px 50px"}}>← See our other works</p>
        </HashLink>
    </div>
  )
}

export default ThankYouMsg