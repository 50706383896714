import React from 'react'
import '../../App.css'
import ThankYouMsg from '../ThankYouMsg'
import Footer from '../Footer';

function FormThankYou() {
  return (
    <>
    <ThankYouMsg />
    <Footer />
    </>
  )
}

export default FormThankYou