import React from 'react';
import CardItem from './CardItem';
import CardItem2 from './CardItem2';
import './Cards.css';
import "@fontsource/league-spartan";

function Cards() {
  return (
    <div className='cards'>
        <h1 id="portfolio">
            Projects
        </h1>
        <div className="cards__container">
            <div className="cards__wrapper">
            <ul className='cards__items'>
                    <CardItem2
                    src='images/tanjong_rhu_com_bed.jpg'
                    text='Tanjong Rhu'
                    subtext='Condo | 2023'
                    // label='Tanjong Rhu | Condo | 2023'
                    path='/tanjongrhu'
                    />
                    <CardItem2
                    src='images/bukit_timah_living_area.jpg'
                    text='Bukit Timah'
                    subtext='Condo | 2023'
                    // label='Bukit Timah | Condo | 2023'
                    path='/bukittimah'
                    />
            </ul>
            <ul className='cards__items'>
                    <CardItem
                    src='images/cck_kitchen.jpeg'
                    text='Choa Chu Kang'
                    subtext='HDB Resale | 2023'
                    // label='Choa Chu Kang | HDB Resale | 2023'
                    path='/cck'
                    />
                    <CardItem
                    src='images/amk_master_bed.jpg'
                    text='Ang Mo Kio'
                    subtext='HDB Resale | 2024'
                    // label='Ang Mo Kio | HDB Resale | 2024'
                    path='/amk'
                    />
                   <CardItem
                    src='images/jervois_road_com_toilet.jpg'
                    text='Jervois Road'
                    subtext='Apartment | 2022'
                    // label='Jervois Road | Apartment | 2022'
                    path='/jervoisroad'
                    />
                </ul>
                <ul className='cards__items'>
                    <CardItem2
                    src='images/serangoon_living.jpg'
                    text='Serangoon'
                    subtext='HDB Resale | 2024'
                    // label='Tanjong Rhu | Condo | 2023'
                    path='/serangoon'
                    />
                    <CardItem2
                    src='images/woodlands_ring_living.png'
                    text='Woodlands Ring'
                    subtext='HDB Resale | 2024'
                    // label='Bukit Timah | Condo | 2023'
                    path='/woodlandsring'
                    />
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Cards