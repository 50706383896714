import React from 'react'
import '../../App.css'
import WoodlandsRingCards from '../WoodlandsRingCards'
import Footer from '../Footer';

function WoodlandsRing() {
  return (
    <>
    <WoodlandsRingCards />
    <Footer />
    </>
  )
}

export default WoodlandsRing