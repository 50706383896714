import React from 'react';
import CardItemEnlarge from './CardItemEnlarge';
import './Cards.css';
import './CardsEnlarge.css'
import "@fontsource/league-spartan";
import { HashLink } from 'react-router-hash-link';

function SerangoonCards() {
  return (
    <div className='cards__e'>
        <h5 id="serangoon">Serangoon</h5>
        <p>HDB Resale | 2024</p>
        <div className="cards__container">
            <div className="cards__wrapper">
                <ul className='cards__items__e'>
                    <CardItemEnlarge src='images/serangoon_living.jpg' />
                    <CardItemEnlarge src='images/serangoon_master_toilet.jpg' />
                </ul>
                <ul className='cards__items__e'>
                    <CardItemEnlarge src='images/serangoon_master_bed.jpg' />
                    <CardItemEnlarge src='images/serangoon_kitchen.jpg' />
                </ul>
            </div>
        </div>
        <HashLink smooth to='/#portfolio' style={{color: "#000"}}>
            <p style={{fontSize: "36px", margin: "-18px 0px 0px", padding: "0px 0px 50px"}}>← See our other works</p>
        </HashLink>
    </div>
  )
}

export default SerangoonCards