import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button';
import { HashLink } from 'react-router-hash-link';
import "@fontsource/alata";
import { animated, useSpring } from '@react-spring/web';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(false);
  const [socials, setSocials] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  
  const handleClick = () => {
    setClick(!click);
    setScrolling(!click);
  }
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    const match = `(max-width: 960px)`;
    if (matchMedia(match).matches) {
        setButton(false)
    } else {
        setButton(true);
    }
  }

  const showSocials = () => {
    const match = `(max-width: 960px)`;
    // if (window.innerWidth <= 960) {
    if (matchMedia(match).matches) {
        setSocials(true)
    } else {
        setSocials(false);
    }
  }

  const handleScroll = (event) => {
    if ((window.scrollY > 0)) {
        setScrolling(true);
    }
    else {
        setScrolling(false);
    }
  };

  const { background, marginTop, height, ...springProps } = useSpring({
    background: scrolling ? "rgba(28, 28, 28, 0.85)" : "transparent",
    marginTop: scrolling ? "-80px" : "-120px",
    height: scrolling ? "80px" : "120px",
    from: {
        background: "transparent",
        marginTop: "-120px",
        height: "120px",
    }
  });


  useEffect(() => {
    showButton(window.matchMedia('(max-width: 960px)').matches);
    showSocials(window.matchMedia('(max-width: 960px)').matches);

    window.addEventListener('resize', showButton);
    window.addEventListener('resize', showSocials);
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('resize', showButton);
        window.removeEventListener('resize', showSocials);
        window.removeEventListener('scroll', handleScroll);
  }
  }, []);

  return (
    <>
        <animated.nav style={{background: background, marginTop: marginTop, ...springProps }} className="navbar">
            <animated.div style={{height: height, ...springProps }} className="navbar-container">
                <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    {/* LY <i className='fab fa-typo3' /> */}
                    <img className='ly-logo' src='images/LY-LOGO_LIGHT-VECTOR.png' alt="LY" class='rounded-circle' width='75px' />
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fa fas fa-times' : 'fa fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <HashLink smooth to='/#start' className='nav-links' onClick={closeMobileMenu}>
                            <p style={{fontFamily: "Alata"}}>GET STARTED</p>
                        </HashLink>
                    </li>
                    <li className='nav-item'>
                        <HashLink smooth to='/#portfolio' className='nav-links' onClick={closeMobileMenu}>
                            <p style={{fontFamily: "Alata"}}>OUR WORK</p>
                        </HashLink>
                    </li>
                    <li className='nav-item'>
                        <Link to='/careers' className='nav-links' onClick={closeMobileMenu}>
                            <p style={{fontFamily: "Alata"}}>LY GROUP</p>
                        </Link>
                    </li>
                    {/* <li className='nav-item'>
                        <Link to='/help' className='nav-links' onClick={closeMobileMenu}>
                            <p style={{fontFamily: "Alata"}}>LY HELP</p>
                        </Link>
                    </li> */}
                    <li className='nav-item'>
                        <HashLink smooth to='/find-us#findus' className='nav-links-mobile' onClick={closeMobileMenu}>
                            <p style={{fontFamily: "Alata"}}>FIND US</p>
                        </HashLink>
                    </li>
                    {socials &&
                        <>
                            <div className='nav-social-icons'>
                                <Link
                                className='social-icon-link facebook'
                                to='https://www.facebook.com/profile.php?id=61557754317103'
                                target='_blank'
                                aria-label='Facebook'
                                >
                                <i className='fab fa-facebook-f' />
                                </Link>
                                <Link
                                className='social-icon-link instagram'
                                to='https://www.instagram.com/lyconsultancygroup?igsh=M3I0ZXdrN2RoaHZl'
                                target='_blank'
                                aria-label='Instagram'
                                >
                                <i className='fab fa-instagram' />
                                </Link>
                                <Link
                                className='social-icon-link tiktok'
                                to='https://www.tiktok.com/@lyconsultancy'
                                target='_blank'
                                aria-label='Tiktok'
                                >
                                <i className='fab fa-tiktok' />
                                </Link>
                            </div>
                            <div className='nav-footer-logo'>
                                <Link to='/' className='social-logo'>
                                {/* LY <i class='fab fa-typo3' /> */}
                                <img className='ly-logo' src='images/LY-LOGO_LIGHT-VECTOR.png' alt="LY" class='rounded-circle' width='55px' />
                                </Link>
                            </div>
                            <small className='website-rights'>LY © 2024</small>
                        </>
                    }
                </ul>
                {button && 
                    <HashLink smooth to='/find-us#findus'>
                        <Button buttonStyle='btn--outline' >
                            <p style={{fontFamily: "Alata", fontSize: "1.3rem"}}>Find Us</p>
                        </Button>
                    </HashLink>}
            </animated.div>
        </animated.nav>
    </>
  )
}

export default Navbar