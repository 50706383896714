import React from 'react'
// import { Link } from 'react-router-dom'

function CardItemEnlarge(props) {
  return (
    <>
        <li className="cards__item__e">
            {/* <Link className="cards__item__link__e" to={props.path}> */}
                {/* <figure className="cards__item__pic-wrap"> */}
                <figure className="cards__item__pic-wrap__e" data-category={props.label}>
                    <img src={props.src} alt="Designs" className="cards__item__img__e" />
                    {/* <figcaption className="cards__item__description">Testing</figcaption> */}
                </figure>
                {/* <div className="cards__item__info">
                    <h5 className="cards__item__text">{props.text}</h5>
                </div> */}
            {/* </Link> */}
        </li>
    </>
  )
}

export default CardItemEnlarge