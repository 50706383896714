import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Home from './components/pages/Home';
// import Portfolio from './components/pages/Portfolio';
import Careers from './components/pages/Careers';
import FindUs from './components/pages/FindUs';
import Amk from './components/pages/AMK';
import Cck from './components/pages/CCK';
import TanjongRhu from './components/pages/Tanjong_Rhu';
import BukitTimah from './components/pages/Bukit_Timah';
import JervoisRoad from './components/pages/Jervois_Road';
import WoodlandsRing from './components/pages/Woodlands_Ring';
import Serangoon from './components/pages/Serangoon';
import FormThankYou from './components/pages/FormThankYou';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          {/* <Route path='/portfolio' element={<Portfolio />} /> */}
          <Route path='/careers' exact element={<Careers />} />
          <Route path='/find-us' exact element={<FindUs />} />
          <Route path='/amk' element={<Amk />} />
          <Route path='/cck' element={<Cck />} />
          <Route path='/tanjongrhu' element={<TanjongRhu />} />
          <Route path='/bukittimah' element={<BukitTimah />} />
          <Route path='/jervoisroad' element={<JervoisRoad />} />
          <Route path='/woodlandsring' element={<WoodlandsRing />} />
          <Route path='/serangoon' element={<Serangoon />} />
          <Route path='/thank-you' element={<FormThankYou />} />
        </Routes>
      </Router>


    </>
  );
}

export default App;
