import React from 'react';
import '../../App.css';
import JourneySection from '../JourneySection';
import Cards from '../Cards';
import Footer from '../Footer';

function Home() {
  return (
    <>
        <JourneySection />
        <Cards />
        <Footer />
    </>
  );
}

export default Home;
