import React from 'react'
import '../../App.css'
import TanjongRhuCards from '../TanjongRhuCards'
import Footer from '../Footer';

function TanjongRhu() {
  return (
    <>
        <TanjongRhuCards />
        <Footer />
    </>
  )
}

export default TanjongRhu