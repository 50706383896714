import React from 'react'
import '../../App.css'
import AmkCards from '../AmkCards';
import Footer from '../Footer';

function Amk() {
  return (
    <>
        <AmkCards />
        <Footer />
    </>
  )
}

export default Amk