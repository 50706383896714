import React from 'react'
import '../../App.css'
import CckCards from '../CckCards';
import Footer from '../Footer';

function Cck() {
  return (
    <>
      <CckCards />
      <Footer />
    </>
  )
}

export default Cck