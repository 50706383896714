import React from 'react'
import '../App.css'
import "@fontsource/league-spartan";

function LYGroup() {
  return (
    <>
      <div className='careers'>
        <div className='careers-title'>
          {/* <h5 className='company-header'>- INTERIOR DESIGN FIRM BASED IN SINGAPORE - <br /></h5> */}
          <h5 className='company-title'>LY CONSULTANCY GROUP</h5>
          <div style={{clear: "left"}} />
        </div>
        <div className='careers-content'>
          <div className='milestone-bg'>
            <img className='ly-logo-dark' src='images/LY-LOGO_DARK-VECTOR.png' alt="LY" />
            <img class='milestone-img' src='images/LYMilestone-noicons.svg' alt="LY Milestones" />
          </div>
          <div className='careers-text'>
            <div className='careers-journey'>
              <h4><strong>The LY Group Journey</strong></h4>
              <div class='text-divider'></div>
              <p>At LY Consultancy Group, we pride ourselves on our extensive expertise in tiling and carpentry, which empowers us to guide clients through every step of their renovation journey with confidence and ease. </p>
              <p>Our approach is rooted in our three core principles: Innovate, Integrate, and Elevate. </p>
              <p>We are committed to <b>innovating</b> by bringing fresh, cutting-edge ideas and solutions to every project, ensuring that our designs are not only current but also forward-thinking. </p>
              <p>We <b>integrate</b> our diverse skills and knowledge to create cohesive and functional spaces that seamlessly blend aesthetics with practicality. </p>
              <p>Finally, we strive to <b>elevate</b> your renovation experience by enhancing both the process and the outcome, delivering exceptional craftsmanship and personalized service that transforms your vision into reality. </p>
              <p>At LY Consultancy Group, our mission is to make your renovation journey smooth, enjoyable, and ultimately rewarding, reflecting our dedication to excellence and our passion for creating beautiful, enduring spaces.</p>
            </div>
            <div className='careers-services'>
              <h4><strong>Start today with us</strong></h4>
              <div class='text-divider'></div>
              <p>Arrange your complimentary consultation now!</p>
              <p><strong>Our range of professional services includes:</strong></p>
              <p>
                - Space Planning and Layout<br/>
                - Mood Board Presentation<br/>
                - 3D Drafting Services<br/>
                - Project Management<br/>
              </p>
              <p>If you have any questions or concerns about your renovation project, please feel free to contact us at your convenience. Your satisfaction and peace of mind are our utmost priorities, and we are always here to offer support and assistance.</p>
              <p>We look forward to the opportunity to collaborate with you and bring your design aspirations to life.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LYGroup