import React from 'react'
import '../../App.css'
import BukitTimahCards from '../BukitTimahCards'
import Footer from '../Footer';

function BukitTimah() {
  return (
    <>
    <BukitTimahCards />
    <Footer />
    </>
  )
}

export default BukitTimah