"use client";

import React, { useState } from 'react'
import { HashLink } from 'react-router-hash-link';
import '../../App.css'
import '../../FindUs.css'
import { Button } from '../Button';
import Footer from '../Footer';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import { useNavigate } from 'react-router-dom';


function FindUs() {
  const position = { lat: 1.29132, lng: 103.85772 };
  const center_position = { lat: 1.301, lng: 103.85772};
  const [open, setOpen] = useState(true);
  // const GMAPS_API_KEY = `${process.env.REACT_APP_GMAPS_API_KEY}`;
  // const GMAPS_MAP_ID = `${process.env.REACT_APP_GMAPS_MAP_ID}`;

  let navigate = useNavigate();
  const submitHandler = (e) =>{
    e.preventDefault();
    let myForm = document.getElementById("enquiry");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate('/thank-you'))
      .catch((error) => alert(error));
  }

  return (
    <>
      <div id="findus" className='find-us'>
        {/* <h1>Journey with us<br /></h1> */}
        <APIProvider apiKey={process.env.REACT_APP_GMAPS_API_KEY}>
          <div className="gmap-style">
            <Map mapId={process.env.REACT_APP_GMAPS_MAP_ID} defaultZoom={14} defaultCenter={center_position} gestureHandling={"cooperative"}>
              <AdvancedMarker position={position} onClick={() => setOpen(true)}>
                <Pin
                  background={"peru"}
                  borderColor={"white"}
                  glyphColor={"lawngreen"}
                />
              </AdvancedMarker>

              {open && (
                <InfoWindow position={position} onCloseClick={() => setOpen(false)}>
                  <div className='address'>
                    <h4 style={{textAlign: "left"}}>Address: </h4>
                    <p style={{textAlign: "left"}}>6 Raffles Blvd, #03-308 Marina Square</p>
                    <p style={{textAlign: "left"}}>Singapore 039594</p>
                    {/* <h4>By appointment only*</h4> */}
                    <p><br/></p>
                  </div>
                </InfoWindow>
              )}
            </Map>
          </div>
        </APIProvider>
        <div className='enquiry-form'>
          {/* <h4>Operating hours: </h4> */}
          <div className='operating-hours'>
            <p> <strong>
              By appointment only* <br/>
              Operating hours: </strong><br/>
              Mon to Fri: 8:00am - 8:00pm <br/>
              Sat to Sun: Accessible only when required</p>
            <p><br/></p>
            <h4 className="gm-iw-email">
              Contact: +65 98295375 <br/>
              Email: admin@lyconsultancygroup.com
            </h4>
            <p><br/></p>
          </div>
          <h1 className='enquiry-form-title'>REACH OUT TO OUR TEAM</h1>
          <p style={{textAlign: "center"}}><b>We'd love to be part of your next home project!</b></p>
          <p>Feel free to fill out the general details of your project below. We'll be in contact soon.</p>
          <div className='form-input'>
            <form name='enquiry' id='enquiry' action="/thank-you" method='post' data-netlify="true" onSubmit={submitHandler}>
              <input type='hidden' name='form-name' value='enquiry' />
              <input
                className='findus-input'
                type='text' required
                id='name'
                name='name'
                placeholder='*Your Name'
              />
              <input
                className='findus-input'
                type='tel'
                id='tel'
                name='number'
                placeholder='Your Phone Number'
              />
              <input
                className='findus-input'
                type='email' required
                id='email'
                name='email'
                placeholder='*Your Email'
              />
              <input
                className='findus-input'
                type='text' required
                id='address'
                name='address'
                placeholder='*Your Address/Area'
              />
              <input
                className='findus-input'
                type='text'
                id='property-type'
                name='property-type'
                placeholder='Property Type'
                list='property-types'
              />
              <datalist id='property-types'>
                <option value="BTO HDB" />
                <option value="Resale HDB" />
                <option value="New Condo" />
                <option value="Resale Condo" />
                <option value="Landed" />
                <option value="Commercial" />
              </datalist>
              <input
                className='findus-input'
                type='text'
                id='key-collection'
                name='key-collection'
                placeholder='Key Collection'
                list='key-collections'
              />
              <datalist id='key-collections'>
                <option value="Current stay-in Project" />
                <option value="Within 1 month" />
                <option value="1 to 3 months" />
                <option value="3 - 6 months" />
                <option value="More than 6 months" />
              </datalist>
              <textarea
                className='findus-message'
                id='message'
                name='message'
                placeholder='Other Details'
                wrap='soft'
              />
              <Button
                className='btn-input' 
                buttonStyle='btn--outline' 
                buttonSize='btn--large'
                type='submit'
              >Submit</Button>
            </form>
          </div>
        </div>
        <HashLink smooth to='/#portfolio' style={{color: "#fff"}}>
            <p style={{fontSize: "36px", margin: "-18px 0px 0px", padding: "0px 0px 50px"}}>
              <br/>
              ← Back to our designs
            </p>
        </HashLink>
      </div>
      <Footer />
    </>
  )
}

export default FindUs