import React from 'react';
// import { useRef, useEffect } from 'react';
import '../App.css';
import { Button } from './Button';
import './JourneySection.css';
import "@fontsource/league-spartan";
import { HashLink } from 'react-router-hash-link';

function JourneySection(props) {
  // const playerRef = useRef(null);
  // let playVideo = (event) => {
  //     if (playerRef.current) {
  //        playerRef.current.play()
  //     }
  //     // playerRef.play()
  // }

  // useEffect(() => {
  //   playVideo()
  // })

  return (
    <div id='start' className='journey-container'>
      {/* <video ref={videoRef} poster="/images/tanjong_rhu_master_bed.jpg" autoPlay loop muted playsInline> */}
      <div dangerouslySetInnerHTML={{ __html: `
        <video
          autoplay
          loop
          muted
          playsinline
          poster="/images/tanjong_rhu_master_bed.jpg"
          src="videos/bg-vid.mp4"
          ref="{playerRef}"
          class="${props.className}">
        </video>
        ` }}></div>
      {/* </video> */}
      <div className='journey-content'>
        <h1>
          <p>
            Welcome to <br/>
            crafted spaces <br/>
            that elevate your <br/>
            home experience.
          </p>
        </h1>
        <div className="journey-btns">
            <HashLink smooth to='/find-us#findus'>
                <Button className='btn' buttonStyle='btn--main--style' buttonSize='btn--main--size'>
                    <p>See the magic</p>
                </Button>
            </HashLink>
        </div>
      </div>
    </div>
  )
}

export default JourneySection;