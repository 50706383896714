import React from 'react'
import '../../App.css'
import JervoisRoadCards from '../JervoisRoadCards'
import Footer from '../Footer';

function JervoisRoad() {
  return (
    <>
        <JervoisRoadCards />
        <Footer />
    </>
  )
}

export default JervoisRoad