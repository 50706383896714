import React from 'react'
import '../../App.css'
import '../../Careers.css'
import "@fontsource/league-spartan";
import LYGroup from '../LYGroup';
import Footer from '../Footer';

function Careers() {
  return (
    <>
        <LYGroup />
        <Footer />
    </>
  )
}

export default Careers