import React from 'react'
import { Link } from 'react-router-dom'

function CardItem2(props) {
  return (
    <>
        <li className="cards__item2">
            <Link className="cards__item__link2" to={props.path}>
                {/* <figure className="cards__item__pic-wrap"> */}
                <figure className="cards__item__pic-wrap2" data-category={props.label}>
                    <img src={props.src} alt="Designs" className="cards__item__img2" />
                    {/* <figcaption className="cards__item__description">Testing</figcaption> */}
                </figure>
                <div className="cards__item__info">
                    <h5 className="cards__item__text">{props.text}</h5>
                    <p className="cards__item__subtext">{props.subtext}</p>
                </div>
            </Link>
        </li>
    </>
  )
}

export default CardItem2