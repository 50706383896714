import React from 'react'
import '../../App.css'
import SerangoonCards from '../SerangoonCards'
import Footer from '../Footer';

function Serangoon() {
  return (
    <>
    <SerangoonCards />
    <Footer />
    </>
  )
}

export default Serangoon